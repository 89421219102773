import './App.css';
import LoadingAnimation from './LoadingAnimation';

function App() {
  return (
    <>
  
    <div className='container'>

    <LoadingAnimation/>
  

    </div>
    </>
  );
}

export default App;
