import React, {useState, useEffect } from 'react';
import gsap from 'gsap';

const GameBox = () => {

    const [cursorVisible, setCursorVisible] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = () => {
        setCursorVisible(true);
      };
    
      const handleMouseLeave = () => {
        setCursorVisible(false);
      };
    
      const handleMouseMove = (e) => {
        setCursorPosition({ x: e.clientX, y: e.clientY });
      };


  return (
    <>
    <h2 className='humor'>Click on the Picture Below to fill Some Humorous shit</h2>
     <a target='_blank' href="https://tally.so/r/3EX9Y2"> 
      <div id="box" className="initial-state customCursorDiv" onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}>

          <h2>Unlock Access – Click Here</h2>
          <img
          src={"images/meme.jpg"}
        
         
         
        />
       
      </div></a>
      {cursorVisible && (
        <img
          src={"images/meme.jpg"}
          alt="Custom Cursor"
          className="customCursor"
          style={{ left: cursorPosition.x, top: cursorPosition.y }}
        />
      )}


   
    </>
  );
};

export default GameBox;
